import {
  A,
  cache,
  createAsync,
  useIsRouting,
  useNavigate,
  useParams,
} from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  onMount,
  Show,
} from "solid-js";
import { razorpayLogoGrey } from "~/assets/assets";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { useModal } from "~/components/modal";
import { GiftCard } from "~/components/pluto/gift-card/gift_card";
import { PaymentFailure } from "~/components/pluto/payment_failure";
import { Stepper } from "~/components/pluto/stepper";
import { payOrderStandardCheckout } from "~/data/products";
import {
  getGiftById,
  getUserProfile,
  initiatePayment,
  updateGiftBudget,
} from "~/server/apis/client_apis";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { goBack, HubbleRoute, pushPage } from "~/shared_states/modal";
import { showSnackBar } from "~/shared_states/snackbar";
import { APIError } from "~/utils/fetch";
import { isMobileDevice } from "~/utils/isMobileDevice";
import { openRazorpayCheckout } from "~/utils/third_party/razorpay";
import { ThreeDotLoader } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export default function PlutoGiftBudget() {
  const params = useParams();
  const navigate = useNavigate();

  const [amount, setAmount] = createSignal("");
  const [error, setError] = createSignal("");
  const [isLoading, setIsLoading] = createSignal(false);

  const { openModal } = useModal()!;

  let inputRef: HTMLInputElement | undefined;

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  const validateAmount = (value: string) => {
    const numValue = parseInt(value, 10);
    if (isNaN(numValue)) {
      setError("Please input a valid amount");
    } else if (numValue < 1000) {
      setError("Minimum amount is Rs. ₹1,000");
    } else if (numValue > 5000) {
      setError("Maximum amount is Rs. ₹5,000");
    } else if (numValue % 500 !== 0) {
      setError("Amount should be a multiple of 500");
    } else {
      setError("");
    }
  };

  const handleInput = (e: Event) => {
    const value = (e.target as HTMLInputElement).value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
      setError("");
    }
  };

  async function onClickSubmitAmount() {
    validateAmount(amount());
    if (error()) {
      return;
    }
    setIsLoading(true);
    try {
      await _initiatePayment();
    } catch (error: any) {
      showSnackBar({ level: "error", message: error.message });
      if (error instanceof APIError && error.code === 401) {
        window.location.href = "/pluto/login";
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  onMount(() => {
    inputRef?.focus();
  });

  async function _initiatePayment() {
    await updateGiftBudget(params.giftId, Number(amount()));
    const initiatePaymentResponse = await initiatePayment(params.giftId);
    if (initiatePaymentResponse.paymentReferenceId) {
      const order = await payOrderStandardCheckout(
        initiatePaymentResponse.paymentReferenceId
      );
      const profile = await getUserProfile();
      openRazorpayCheckout({
        amount: Number(amount()),
        email: profile.email ?? "",
        mode: "CARD_AND_UPI",
        orderId: order.providerOrderId,
        phone: profile.phoneNumber ?? "",
        onSuccess: () => {
          navigate(`/pluto/create/${params.giftId}/share`, {
            state: { status: "success", budget: Number(amount()) },
          });
        },
        onFailure: () => {
          if (isMobileDevice()) {
            openPaymentFailureModal();
          } else {
            navigate(`/pluto/create/${params.giftId}/share`, {
              state: { status: "failure" },
            });
          }
        },
      });
    }
  }

  const openPaymentFailureModal = () => {
    openModal(
      () => {
        return <PaymentFailure modal={true} />;
      },
      "dark !w-[400px] h-[311px]",
      "!p-0 !rounded-2xl"
    );
  };

  return (
    <Show when={giftCardDetails()} fallback={<DottedLoader color="#999" />}>
      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <div class="flex h-full w-full flex-col overflow-y-auto text-white md:w-[800px] lg:w-screen">
        <div class="relative flex min-h-14 items-center justify-center bg-plutoHeaderBg bg-cover bg-no-repeat px-4 py-2 lg:border-b lg:border-basePrimaryMedium">
          <button
            onClick={() => {
              goBack();
            }}
            class="absolute left-4 top-2 flex h-10 w-10 items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-2"
          >
            <PhosphorIcon
              name="arrow-left"
              fontSize={24}
              size="light"
              class="cursor-pointer text-white"
            />
          </button>
          <p class="text-center text-smallBold uppercase tracking-[1.1px] text-white">
            Gift for {giftCardDetails()?.content.text.occasion.recipientName}
          </p>
        </div>
        <div class="lg:mx-auto lg:w-[766px]">
          <Stepper activeStep={1} />
          <div class="lg:mt-8 lg:flex lg:gap-20">
            <div class="hidden lg:block">
              <GiftCard
                giftDetails={() => giftCardDetails()!}
                flipOnCard={true}
                showActions={false}
                autoFlip={false}
                showNavigationDots={true}
                showActionsBg={false}
              />
            </div>
            <div class="w-full">
              <div class="mb-2 mt-6 text-center lg:mt-0 lg:text-start">
                <h1 class="font-jakartaSans text-[28px] font-semibold">
                  Load money
                </h1>
                <p class="text-medium text-textNormal">
                  Between ₹1,000 - ₹5,000
                </p>
              </div>
              <div class="relative mx-auto flex h-[195px] justify-center p-4">
                <div class="animated-border-box-glow"></div>
                <div class="animated-border-box rounded-[20px] border border-basePrimaryDark p-4">
                  <div class="relative">
                    <input
                      type="number"
                      ref={(el) => (inputRef = el)}
                      inputMode="numeric"
                      class={`block h-[44px] w-full rounded-lg border ${
                        error() ? "border-errorDark" : "border-white"
                      } border-basePrimaryLight bg-transparent p-3 ps-10 text-[16px] font-semibold placeholder:text-medium placeholder:text-textNormal focus:z-10 focus:border-white focus:ring-white disabled:pointer-events-none disabled:opacity-50`}
                      placeholder="Enter amount"
                      value={amount()}
                      onInput={handleInput}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onClickSubmitAmount();
                        }
                      }}
                    />
                    <div class="pointer-events-none absolute inset-y-0 start-0 z-20 flex items-center ps-4">
                      <p class="text-normal">₹</p>
                    </div>
                  </div>
                  <Show when={error()}>
                    <p class="mt-1 text-medium text-errorDark">{error()}</p>
                  </Show>
                  <button
                    class="mb-3 mt-5  flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3  text-buttonMedium font-bold text-textDark"
                    disabled={!!error() || !amount() || isLoading()}
                    onClick={onClickSubmitAmount}
                  >
                    {isLoading() ? (
                      <ThreeDotLoader color="#313538" />
                    ) : (
                      <>Proceed to pay</>
                    )}
                  </button>
                  <div class="flex items-center justify-center self-stretch">
                    <div class=" text-medium text-textNormal">
                      Secure payment by
                    </div>
                    <img
                      class="h-4 w-20"
                      src={razorpayLogoGrey}
                      alt="Secure payments"
                    />
                  </div>
                </div>
              </div>

              <div
                class="my-12 flex w-full cursor-pointer justify-center text-buttonMedium underline"
                onClick={() => {
                  window.open(getPreviewUrl(), "_blank");
                }}
              >
                Receiver’s preview
              </div>
            </div>
          </div>
        </div>
      </div>
    </Show>
  );

  function getPreviewUrl(): string {
    let url = giftCardDetails()?.receiversPreviewUrl;
    if (!url) {
      showSnackBar({
        level: "error",
        message: "Unable to load preview. Please try again later.",
      });
      return "";
    }

    if (amount()) {
      validateAmount(amount());
      if (!error()) {
        url += `&amount=${amount()}`;
        return url;
      }
    }

    return url;
  }
}
